.title {
  display: block;
  font-size: .875em;
  color: var(--label-font-color);
}

.wrapper-horizontal {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(290px, 1fr));
  grid-gap: 20px;
  margin-bottom: var(--space-xl);
}

.inputList {
  list-style: none;
  padding-left: 0;
  margin: var(--space-s) 0;
}

.inputListItem + .inputListItem {
  margin-top: var(--space-s);
}

.inputLabelWrapper {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
}

.inputLabel {
  margin-left: var(--space-s);
}
